/* eslint-disable no-inline-comments */
import React, { lazy } from 'react';
import { Route } from 'react-router-dom';

import {
  URL_HIN_LOGIN,
  URL_LOGIN,
  URL_LOGIN_ACTIVATION,
  URL_FORGOT_PASSWORD,
  URL_RESET_PASSWORD,
  URL_DOCTOR_LOGIN,
  URL_ROOT,
  URL_SERVER_MESSAGES,
  URL_SESSION_EXPIRED,
  URL_USER_NOT_ELIGIBLE,
  URL_USER_REGISTER_CONFIRM_NEW_USER,
  URL_USER_REGISTER_TERMS_OF_USE,
  URL_USER_REGISTER_E_CONSENT,
  URL_USER_REGISTER_FORM,
  URL_USER_REGISTER_EMAIL_VERIFICATION,
  URL_PROVIDER_REGISTER,
  URL_PUBLIC_ABOUT_US
} from './constants/urls';

/* lazy callbacks to render component */
const HINLogin = () =>
  lazy(() =>
    import(/* webpackChunkName: "HINLogin" */ './pages/public/HINLogin')
  );
/* lazy callbacks to render component */
const DoctorLogin = () =>
  lazy(() =>
    import(/* webpackChunkName: "DoctorLogin" */ './modules/CDSS/pages/public/DoctorLogin')
  );
const FourOhFour = () =>
  lazy(() =>
    import(/* webpackChunkName: "FourOhFour" */ './pages/public/FourOhFour')
  );
const Home = () =>
  lazy(() => import(/* webpackChunkName: "Home" */ './pages/public/Home'));
const LoginActivation = () =>
  lazy(() =>
    import(/* webpackChunkName: "LoginActivation" */ './pages/public/LoginActivation')
  );
const ForgotPassword = () =>
  lazy(() =>
    import(/* webpackChunkName: "ForgotPassword" */ './pages/public/ForgotPassword')
  );
const ResetPassword = () =>
  lazy(() =>
    import(/* webpackChunkName: "ResetPassword" */ './pages/public/ResetPassword')
  );
const Login = () =>
  lazy(() => import(/* webpackChunkName: "Login" */ './pages/public/Login'));
const ServerMessages = () =>
  lazy(() =>
    import(/* webpackChunkName: "ServerMessages" */ './pages/public/ServerMessages')
  );
const SessionExpired = () =>
  lazy(() =>
    import(/* webpackChunkName: "SessionExpired" */ './pages/public/SessionExpired')
  );
const UserRegisterConfirmNewUser = () =>
  lazy(() =>
    import(/* webpackChunkName: "UserRegisterConfirmNewUser" */ './pages/public/UserRegister/UserRegisterConfirmNewUser')
  );
const UserRegisterTermsOfUse = () =>
  lazy(() =>
    import(/* webpackChunkName: "UserRegisterTermsOfUse" */ './pages/public/UserRegister/UserRegisterTermsOfUse')
  );
const UserRegisterEConsent = () =>
  lazy(() =>
    import(/* webpackChunkName: "UserRegisterEConsent" */ './pages/public/UserRegister/UserRegisterEConsent')
  );
const UserRegisterForm = () =>
  lazy(() =>
    import(/* webpackChunkName: "UserRegisterForm" */ './pages/public/UserRegister/UserRegisterForm')
  );
const UserNotEligible = () =>
  lazy(() =>
    import(/* webpackChunkName: "UserRegisterForm" */ './pages/public/UserNotEligible')
  );
const UserRegisterEmailVerification = () =>
  lazy(() =>
    import(/* webpackChunkName: "UserRegisterEmailVerification" */ './pages/public/UserRegister/UserRegisterEmailVerification')
  );
const ProviderRegister = () =>
  lazy(() =>
    import(/* webpackChunkName: "ProviderRegister" */ './pages/public/ProviderRegister')
  );
const MainAboutUs = () =>
  lazy(() =>
    import(/* webpackChunkName: "SecureAboutUs" */ './pages/user/AboutUsPage')
  );

/* exported routes */
export const HINLoginRoute = (
  <Route component={HINLogin()} exact path={URL_HIN_LOGIN} />
);
export const DoctorLoginRoute = (
  <Route component={DoctorLogin()} exact path={URL_DOCTOR_LOGIN} />
);
export const FourOhFourRoute = <Route component={FourOhFour()} exact />;
export const HomeRoute = <Route component={Home()} exact path={URL_ROOT} />;
export const LoginActivationRoute = (
  <Route component={LoginActivation()} exact path={URL_LOGIN_ACTIVATION} />
);
export const ForgotPasswordRoute = (
  <Route component={ForgotPassword()} exact path={URL_FORGOT_PASSWORD} />
);
export const ResetPasswordRoute = (
  <Route component={ResetPassword()} exact path={URL_RESET_PASSWORD} />
);
export const LoginRoute = <Route component={Login()} exact path={URL_LOGIN} />;
export const ServerMessagesRoute = (
  <Route component={ServerMessages()} exact path={URL_SERVER_MESSAGES} />
);
export const SessionExpiredRoute = (
  <Route component={SessionExpired()} exact path={URL_SESSION_EXPIRED} />
);
export const UserRegisterConfirmNewUserRoute = (
  <Route
    component={UserRegisterConfirmNewUser()}
    exact
    path={URL_USER_REGISTER_CONFIRM_NEW_USER}
  />
);
export const UserRegisterTermsOfUseRoute = (
  <Route
    component={UserRegisterTermsOfUse()}
    exact
    path={URL_USER_REGISTER_TERMS_OF_USE}
  />
);
export const UserRegisterEConsentRoute = (
  <Route
    component={UserRegisterEConsent()}
    exact
    path={URL_USER_REGISTER_E_CONSENT}
  />
);
export const UserNotEligibleRoute = (
  <Route component={UserNotEligible()} exact path={URL_USER_NOT_ELIGIBLE} />
);
export const UserRegisterFormRoute = (
  <Route component={UserRegisterForm()} exact path={URL_USER_REGISTER_FORM} />
);
export const UserRegisterEmailVerificationRoute = (
  <Route
    component={UserRegisterEmailVerification()}
    exact
    path={URL_USER_REGISTER_EMAIL_VERIFICATION}
  />
);
export const ProviderRegisterRoute = (
  <Route component={ProviderRegister()} exact path={URL_PROVIDER_REGISTER} />
);

export const MainAboutUsRoute = (
  <Route component={MainAboutUs()} exact path={URL_PUBLIC_ABOUT_US} />
);
