import cdssUrls from '_modules/CDSS/constants/urls';
import decisionAidUrls from '_modules/DecisionAid/constants/urls';

const basePublicPath = '/main';
const basePublicUserRegisterPath = `${basePublicPath}/user/register`;
const baseSecureUserPath = '/secure/user';
// const baseSecureServicePath = '/secure/service';
const baseProviderRegisterPath = `${basePublicPath}/:clinicId/provider/register`;

// public
export const URL_LOGIN = `${basePublicPath}/login`;
export const URL_HIN_LOGIN = `${basePublicPath}/hin/login`;
export const URL_LOGIN_TEST = `${basePublicPath}/login/test`;
export const URL_LOGIN_ACTIVATION = `${basePublicPath}/login/activation/:tokenUUID`;
export const URL_FORGOT_PASSWORD = `${basePublicPath}/login/password`;
export const URL_RESET_PASSWORD = `${basePublicPath}/login/password/reset/:tokenUUID`;
export const URL_ROOT = '/';
export const URL_SESSION_EXPIRED = `${basePublicPath}/session/expired`;
export const URL_USER_NOT_ELIGIBLE = `${basePublicUserRegisterPath}/not-eligible`;
// export const URL_USER_REGISTER_MAYBE_LOGIN = `${basePublicUserRegisterPath}/maybe-login`;
export const URL_USER_REGISTER_CONFIRM_NEW_USER = `${basePublicUserRegisterPath}/confirm-new-user`;
export const URL_USER_REGISTER_TERMS_OF_USE = `${basePublicUserRegisterPath}/terms-of-use`;
export const URL_USER_REGISTER_E_CONSENT = `${basePublicUserRegisterPath}/e-consent`;
export const URL_USER_REGISTER_FORM = basePublicUserRegisterPath;
export const URL_USER_REGISTER_EMAIL_VERIFICATION = `${basePublicUserRegisterPath}/email/verification`;
export const URL_SERVER_MESSAGES = '/server/messages';
export const URL_PROVIDER_REGISTER = baseProviderRegisterPath;
export const URL_PROVIDER_AUTO_AUTH =
  '/patients/cdss/authprovider/:pNo/:patientHash/:pNa';
export const URL_PROVIDER_AUTH_QUESTIONNAIRE =
  '/patients/cdss/authpatient/:payloadBase64';
export const URL_LOGOUT_REDIRECT = '/logout/redirect';
export const URL_PUBLIC_ABOUT_US = `${basePublicPath}/about-us`;

// secure user
export const URL_SECURE_USER_DASHBOARD = `${baseSecureUserPath}/dashboard`;
export const URL_SECURE_USER_PROFILE = `${baseSecureUserPath}/profile`;
export const URL_SECURE_USER_ABOUT_US = `${baseSecureUserPath}/about-us`;
export const URL_SECURE_USER_ASTHMA_ACTION_PLAN = `${baseSecureUserPath}/asthma-action-plan`;
export const URL_SECURE_USER_DECISION_AID = decisionAidUrls.URL_DECISION_AID;
export const URL_SECURE_USER_DECISION_AID_CONTENT =
  decisionAidUrls.URL_DECISION_AID_CONTENT;
export const URL_SECURE_USER_DECISION_AID_HISTORY =
  decisionAidUrls.URL_DECISION_AID_HISTORY;
export const URL_SECURE_USER_EDUCATION = `${baseSecureUserPath}/education`;
export const URL_SECURE_USER_QUESTIONNAIRE = `${baseSecureUserPath}/questionnaire`;
export const URL_SECURE_USER_GLOSSARY = `${baseSecureUserPath}/glossary`;
export const URL_SECURE_USER_HELPFUL_LINKS = `${baseSecureUserPath}/helpful-links`;

// questionnaire
export const URL_QUESTIONNAIRE = '/questionnaire/secure/patient/questionnaire';

// secure doctor
export const URL_DOCTOR_LOGIN = cdssUrls.URL_DOCTOR_LOGIN;
export const URL_SECURE_DOCTOR_CDSS_CHART_NOTES_TESTS =
  cdssUrls.URL_SECURE_DOCTOR_CDSS_CHART_NOTES_TESTS;
export const URL_SECURE_DOCTOR_CDSS_TRANSFORM_MEDICATION_TESTS =
  cdssUrls.URL_SECURE_DOCTOR_CDSS_TRANSFORM_MEDICATION_TESTS;
export const URL_SECURE_DOCTOR_CDSS_PATIENTS =
  cdssUrls.URL_SECURE_DOCTOR_CDSS_PATIENTS;
export const URL_SECURE_DOCTOR_CDSS_PATIENT =
  cdssUrls.URL_SECURE_DOCTOR_CDSS_PATIENT;
export const URL_SECURE_DOCTOR_CDSS_PATIENT_ASTHMA_CONTROL =
  cdssUrls.URL_SECURE_DOCTOR_CDSS_PATIENT_ASTHMA_CONTROL;
export const URL_SECURE_DOCTOR_CDSS_PATIENT_MEDICATION_CHANGE =
  cdssUrls.URL_SECURE_DOCTOR_CDSS_PATIENT_MEDICATION_CHANGE;
export const URL_SECURE_DOCTOR_CDSS_PATIENT_AAP =
  cdssUrls.URL_SECURE_DOCTOR_CDSS_PATIENT_AAP;
export const URL_SECURE_DOCTOR_CDSS_PATIENT_AAP_REPORT =
  cdssUrls.URL_SECURE_DOCTOR_CDSS_PATIENT_AAP_REPORT;
export const URL_SECURE_DOCTOR_CDSS_PATIENT_COMPLETE =
  cdssUrls.URL_SECURE_DOCTOR_CDSS_PATIENT_COMPLETE;
export const URL_SECURE_DOCTOR_QUESTIONNAIRE =
  cdssUrls.URL_SECURE_DOCTOR_QUESTIONNAIRE;
