/* Question Ids */
export const QUESTION_ID_PATIENT_DOES_NOT_HAVE_ASTHMA =
  'patient_does_not_have_asthma';
export const QUESTION_ID_PATIENT_IS_PREGNANT = 'patient_is_pregnant';
export const QUESTION_ID_PREPEND_CHART_NOTE_MESSAGES =
  'prepended_chart_note_messages';
export const QUESTION_ID_MRP_MESSAGE = 'mrp_message';
export const QUESTION_ID_PATIENT_NOT_SUITABLE = 'patient_not_suitable_for_aap';
export const QUESTION_ID_AAP_DEFERED = 'aap_defered';
export const QUESTION_ID_AAP_CONTINUED = 'aap_continued';
export const QUESTION_ID_AAP_APPROVED = 'aap_approved';
export const QUESTION_ID_AAP_PRINTED = 'aap_printed';
export const QUESTION_ID_AAP_COMPLETED = 'aap_completed';
export const QUESTION_ID_QUESTIONNAIRE_COMPLETED = 'questionnaire_completed';

/* Survey Link UUIDs */
export const SURVEY_LINK_UUID_QUESTIONNAIRE_COMPLETED =
  '92646F66-5176-44CA-AFA5-A9D660CCAEF0';
export const SURVEY_LINK_UUID_AAP_DEFERED =
  '6EAACC26-5368-4204-809A-4F2001834DC0';
export const SURVEY_LINK_UUID_AAP_CONTINUED =
  '2CFA8E81-A0B5-44C7-BB8B-1EE0946C563C';
export const SURVEY_LINK_UUID_AAP_APPROVED =
  '0F766AB1-6C09-423E-A18A-4EA14492C804';
export const SURVEY_LINK_UUID_AAP_PRINTED =
  '71CF7B98-BF27-492A-9F97-72EB089544F5';
export const SURVEY_LINK_UUID_AAP_COMPLETED =
  '94314224-C078-4670-8BCF-6B9C974F8CE6';
export const SURVEY_LINK_UUID_PATIENT_NOT_SUITABLE =
  '2BA39AC5-C9A9-4188-85F3-0006B6154B2F';
export const SURVEY_LINK_UUID_PATIENT_DOES_NOT_HAVE_ASTHMA =
  'CB570A1E-DAAD-44FB-8ABD-3376268BE949';
export const SURVEY_LINK_UUID_PATIENT_IS_PREGNANT =
  'D1F3DC7D-FBB5-4460-BB0A-B881F7947D7E';
export const SURVEY_LINK_UUID_PREPEND_CHART_NOTE_MESSAGES =
  '471FF7A2-8FD8-4002-924C-D6F345F88622';
export const SURVEY_LINK_UUID_MRP_MESSAGE =
  '50CA9011-DA97-4FCC-9F5F-C18C5934E698';
// "On average, are you woken up by asthma symptoms at night 1 or more nights each week?"
export const SURVEY_LINK_UUID_CONTROL_QUESTION_1 =
  '85D02810-69D5-4D4A-9F93-4095F7A54028';
// "On average, on how many days each week</strong> do you have asthma symptoms (for example)?"
export const SURVEY_LINK_UUID_CONTROL_QUESTION_2 =
  '902BAC13-1570-4088-98A6-68A05C0761FA';
// "A rescue puffer is one that you take for immediate relief of asthma symptoms, not one that you take on a regular basis
// (i.e. Ventolin, Salbutamol, Apo-Salbutamol, Atrovent, Bricanyl, and Terbutaline). On average, how many times each week do you need to use a rescue puffer?"
export const SURVEY_LINK_UUID_CONTROL_QUESTION_3 =
  '3354E0A5-328B-49E9-B3FF-C71F9D87A925';
// "Have you missed work, school and/or other activities because of your asthma (in the last 3 months)?"
export const SURVEY_LINK_UUID_CONTROL_QUESTION_4 =
  '104C23FF-EABD-407E-9B1A-2C014C5AC8CE';
// "Has there been a time when you were exercising and had to stop because of your asthma symptoms (in the past 3 months)?"
export const SURVEY_LINK_UUID_CONTROL_QUESTION_5 =
  'A24661BC-7D05-4C86-B81C-A53DD7536484';
// "Have you had a flare-up such as this since the last time your asthma medications were changed?"
export const SURVEY_LINK_UUID_CONTROL_QUESTION_6 =
  '255F0461-9618-4A5F-B961-8D7ECA1A179B';
export const SURVEY_LINK_UUID_SMOKING = '17258242-6BBD-40DC-8BBA-E2A48BA8E73D';
export const SURVEY_LINK_UUID_FLARE = '99F46447-097D-4AE9-BD23-059966272AAA';
export const SURVEY_LINK_UUID_SCREEN = 'CE3B4256-6BCC-4B35-A641-B2D88CBCA36A';
// "Do you currently smoke cigarettes?"
export const SURVEY_LINK_UUID_EXACERBATION_SMOKE =
  '17258242-6BBD-40DC-8BBA-E2A48BA8E73D';
export const SURVEY_LINK_UUID_EXACERBATION_FLARE_PILLS_VISIT =
  '99F46447-097D-4AE9-BD23-059966272AAA';
// "Emergency contact name"
export const SURVEY_LINK_UUID_EMERGENCY_CONTACT_NAME =
  '82D8F1D4-F49B-48FF-9B1E-3345C6D446DE';
// "Emergency contact phone number"
export const SURVEY_LINK_UUID_EMERGENCY_CONTACT_PHONE =
  'A9A8FE43-397D-47BB-B576-BABC26F649DB';
// "Meds screen reached"
export const SURVEY_LINK_UUID_MEDS_SCREEN_REACHED =
  '4F474F92-BAAA-43A8-9A03-B1DA25555643';

/* Survey UUIDs */
export const SURVEY_UUID_QUESTIONNAIRE = '096BC390-FE64-454E-9D19-B5B695B7F709';

/* Option UUIDs */
export const OPTION_UUID_YES = '9D106656-E289-448D-A04C-8DA6C3F34128';
export const OPTION_UUID_NO = '55CF0714-24C4-4593-B8B2-C3FF795B5171';

/* other */
export const MEDICATION_STATUS = {
  DRAFT: 1,
  SUBMITTED: 2
};
