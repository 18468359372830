import { connect as reduxConnect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from './actions';
import reducer from './reducer';

const mapStateToProps = ({ cache }) => ({ cache });

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      deleteInstanceLinks: actions.deleteInstanceLinks,
      deleteInstancesResponses: actions.deleteInstancesResponses,
      fetchInstances: actions.fetchInstances,
      fetchInstancesQuestions: actions.fetchInstancesQuestions,
      fetchInstancesQuestionsOptions: actions.fetchInstancesQuestionsOptions,
      fetchInstancesResponses: actions.fetchInstancesResponses,
      fetchInstanceLinks: actions.fetchInstanceLinks,
      fetchInstanceLinksWithInstancesResponses:
        actions.fetchInstanceLinksWithInstancesResponses,
      initializeCache: actions.initializeCache,
      postInstanceLinks: actions.postInstanceLinks,
      postInstancesResponses: actions.postInstancesResponses,
      updateInstancesReponses: actions.updateInstancesReponses,
      fetchInstancesResponsesFulfilled: actions.fetchInstancesResponsesFulfilled
    },
    dispatch
  );

const connect = component =>
  reduxConnect(mapStateToProps, mapDispatchToProps)(component);

export default {
  actions,
  connect,
  reducer
};
