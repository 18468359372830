import { chain, keyBy, map, pickBy, isNaN } from 'lodash';
import sdk, { utils as sdkUtils } from '@eams-dev/js-qoc-sdk';
import { TYPES } from './constants';
import extractHttpResponse from '../utils/extractHttpResponse';
import fulfillAction from '../utils/fulfillAction';
import rejectAction from '../utils/rejectAction';
import questionData from './surveyQuestions.json';

/**
 * Delete Instance Links
 */
export const deleteInstanceLinks = (props = {}) => async dispatch => {
  dispatch({
    type: TYPES.DELETE_INSTANCE_LINKS_PENDING
  });

  const {
    requestProps = {},
    onFulfilled,
    onFulfilledMessage,
    onRejected,
    onRejectedMessage
  } = props;
  const { instanceLinkUUID, instanceLinkUUIDs } = requestProps;

  try {
    const response = await sdk.deleteInstanceLinks(requestProps);
    const { status, statusText } = extractHttpResponse({ response });

    if (status === 200) {
      return dispatch(
        fulfillAction({
          instanceLinkUUIDs: instanceLinkUUIDs || [instanceLinkUUID],
          message: onFulfilledMessage || statusText,
          onFulfilled,
          status,
          type: TYPES.DELETE_INSTANCE_LINKS_FULFILLED
        })
      );
    }

    throw new Error('Status not 200');
  } catch (error) {
    return dispatch(
      rejectAction({
        error,
        onRejected,
        onRejectedMessage,
        type: TYPES.DELETE_INSTANCE_LINKS_REJECTED
      })
    );
  }
};

/**
 * Delete Instances Responses
 */
export const deleteInstancesResponses = (props = {}) => async dispatch => {
  dispatch({
    type: TYPES.DELETE_INSTANCES_RESPONSES_PENDING
  });

  const {
    requestProps = {},
    onFulfilled,
    onFulfilledMessage,
    onRejected,
    onRejectedMessage
  } = props;
  const { surveyResponseUUID, surveyResponseUUIDs } = requestProps;

  try {
    const response = await sdk.deleteInstancesSurveysResponses(requestProps);
    const { status, statusText } = extractHttpResponse({ response });

    if (status === 200) {
      return dispatch(
        fulfillAction({
          message: onFulfilledMessage || statusText,
          onFulfilled,
          status,
          surveyResponseUUIDs: surveyResponseUUIDs || [surveyResponseUUID],
          type: TYPES.DELETE_INSTANCES_RESPONSES_FULFILLED
        })
      );
    }

    throw new Error('Status not 200');
  } catch (error) {
    return dispatch(
      rejectAction({
        error,
        onRejected,
        onRejectedMessage,
        type: TYPES.DELETE_INSTANCES_RESPONSES_REJECTED
      })
    );
  }
};

/**
 * Fetch Instance Links
 */
export const fetchInstanceLinksFulfilled = (props = {}) =>
  fulfillAction({
    ...props,
    type: TYPES.FETCH_INSTANCE_LINKS_FULFILLED
  });
export const fetchInstanceLinksPending = (props = {}) =>
  fulfillAction({
    ...props,
    type: TYPES.FETCH_INSTANCE_LINKS_PENDING
  });
export const fetchInstanceLinksRejected = (props = {}) =>
  rejectAction({
    ...props,
    type: TYPES.FETCH_INSTANCE_LINKS_REJECTED
  });
export const fetchInstanceLinks = (props = {}) => async dispatch => {
  dispatch(fetchInstanceLinksPending());

  const {
    requestProps = {},
    onFulfilled,
    onFulfilledMessage,
    onRejected,
    onRejectedMessage
  } = props;

  try {
    const response = await sdk.getInstanceLinks(requestProps);
    const { data = {}, status, statusText } = extractHttpResponse({ response });

    if (status === 200 || status === 404) {
      const instanceLinks = keyBy(
        Array.isArray(data) ? data : [data],
        'instanceLinkUUID'
      );

      return dispatch(
        fetchInstanceLinksFulfilled({
          instanceLinks,
          message: onFulfilledMessage || statusText,
          onFulfilled,
          status
        })
      );
    }

    throw new Error('Status not 200 or 404');
  } catch (error) {
    return dispatch(
      fetchInstanceLinksRejected({
        error,
        onRejected,
        onRejectedMessage
      })
    );
  }
};

/**
 * Fetch Instances
 */
export const fetchInstances = (props = {}) => async dispatch => {
  dispatch({
    type: TYPES.FETCH_INSTANCES_PENDING
  });

  const {
    requestProps = {},
    onFulfilled,
    onFulfilledMessage,
    onRejected,
    onRejectedMessage
  } = props;

  try {
    const response = await sdk.getInstances(requestProps);
    const { data = {}, status, statusText } = extractHttpResponse({ response });

    if (status === 200 || status === 404) {
      const instances = keyBy(data, 'instanceUUID');

      return dispatch(
        fulfillAction({
          instances,
          message: onFulfilledMessage || statusText,
          onFulfilled,
          status,
          type: TYPES.FETCH_INSTANCES_FULFILLED
        })
      );
    }

    throw new Error('Status not 200 or 404');
  } catch (error) {
    return dispatch(
      rejectAction({
        error,
        onRejected,
        onRejectedMessage,
        type: TYPES.FETCH_INSTANCES_REJECTED
      })
    );
  }
};

/**
 * Fetch Instances Questions
 */
export const fetchInstancesQuestions = (props = {}) => async dispatch => {
  dispatch({
    type: TYPES.FETCH_INSTANCES_QUESTIONS_PENDING
  });

  const { onFulfilled, onFulfilledMessage } = props;

  const questions = keyBy(questionData, 'questionUUID');

  return dispatch(
    fulfillAction({
      message: onFulfilledMessage,
      onFulfilled,
      questions,
      status: 200,
      type: TYPES.FETCH_INSTANCES_QUESTIONS_FULFILLED
    })
  );
};

/**
 * Fetch Instances Questions Options
 */
export const fetchInstancesQuestionsOptions = (
  props = {}
) => async dispatch => {
  dispatch({
    type: TYPES.FETCH_INSTANCES_QUESTIONS_OPTIONS_PENDING
  });

  const {
    requestProps = {},
    onFulfilled,
    onFulfilledMessage,
    onRejected,
    onRejectedMessage
  } = props;

  try {
    const response = await sdk.getInstancesQuestionsOptions(requestProps);
    const { data = {}, status, statusText } = extractHttpResponse({ response });

    if (status === 200 || status === 404) {
      const options = keyBy(data, 'optionUUID');

      return dispatch(
        fulfillAction({
          message: onFulfilledMessage || statusText,
          onFulfilled,
          options,
          status,
          type: TYPES.FETCH_INSTANCES_QUESTIONS_OPTIONS_FULFILLED
        })
      );
    }

    throw new Error('Status not 200 or 404');
  } catch (error) {
    return dispatch(
      rejectAction({
        error,
        onRejected,
        onRejectedMessage,
        type: TYPES.FETCH_INSTANCES_QUESTIONS_OPTIONS_REJECTED
      })
    );
  }
};

/**
 * Fetch Instances Responses
 */
export const fetchInstancesResponses = (props = {}) => async dispatch => {
  dispatch({
    type: TYPES.FETCH_INSTANCES_RESPONSES_PENDING
  });

  const {
    requestProps = {},
    onFulfilled,
    onFulfilledMessage,
    onRejected,
    onRejectedMessage
  } = props;

  try {
    const response = await sdk.getInstancesResponses(requestProps);
    const { data = {}, status, statusText } = extractHttpResponse({ response });

    if (status === 200 || status === 404) {
      const responses = keyBy(data, 'surveyResponseUUID');

      return dispatch(
        fulfillAction({
          message: onFulfilledMessage || statusText,
          onFulfilled,
          responses,
          status,
          type: TYPES.FETCH_INSTANCES_RESPONSES_FULFILLED
        })
      );
    }

    throw new Error('Status not 200 or 404');
  } catch (error) {
    return dispatch(
      rejectAction({
        error,
        onRejected,
        onRejectedMessage,
        type: TYPES.FETCH_INSTANCES_RESPONSES_REJECTED
      })
    );
  }
};

/**
 * Fetch Instance Links and Survey Responses
 */
export const fetchInstanceLinksWithInstancesResponses = (
  props = {}
) => dispatch => {
  const {
    targetInstanceLinkResponses = true,
    instanceLinksRequestProps = {},
    instancesResponsesRequestProps = {}
  } = props;

  if (targetInstanceLinkResponses) {
    return dispatch(
      fetchInstanceLinks({ requestProps: instanceLinksRequestProps })
    ).then(({ instanceLinks }) => {
      const instanceLinkUUIDs = map(instanceLinks, 'instanceLinkUUID');

      return dispatch(
        fetchInstancesResponses({
          requestProps: {
            instanceLinkUUIDs,
            ...instancesResponsesRequestProps
          }
        })
      );
    });
  }

  return dispatch(fetchInstanceLinks(instanceLinksRequestProps)).then(() =>
    dispatch(fetchInstancesResponses(instancesResponsesRequestProps))
  );
};

/**
 * Initialize Cache
 */
export const initializeCache = (props = {}) => (dispatch, getState) => {
  dispatch({
    type: TYPES.INITIALIZE_CACHE_PENDING
  });

  const {
    onFulfilled,
    onFulfilledMessage,
    onRejected,
    onRejectedMessage
  } = props;
  const errorMessage = 'Cache initialize failed';
  const successMessage = 'Cache initialize successfull';

  return Promise.all([
    dispatch(fetchInstances()),
    dispatch(fetchInstanceLinks()),
    dispatch(fetchInstancesQuestions()),
    dispatch(fetchInstancesQuestionsOptions()),
    dispatch(fetchInstancesResponses())
  ])
    .then(() =>
      dispatch(
        fulfillAction({
          cache: getState().cache,
          message: onFulfilledMessage || successMessage,
          onFulfilled,
          status: 200,
          type: TYPES.INITIALIZE_CACHE_FULFILLED
        })
      )
    )
    .catch(() =>
      dispatch(
        rejectAction({
          error: {
            message: errorMessage,
            status: 400
          },
          onRejected,
          onRejectedMessage,
          type: TYPES.INITIALIZE_CACHE_REJECTED
        })
      )
    );
};

/**
 * Post Instances Responses
 */
export const postInstancesResponses = (props = {}) => async (
  dispatch,
  getState
) => {
  dispatch({
    type: TYPES.POST_INSTANCES_RESPONSES_PENDING
  });

  const {
    requestProps = {},
    onFulfilled,
    onFulfilledMessage,
    onRejected,
    onRejectedMessage
  } = props;
  const { surveyResponses, ...otherRequestProps } = requestProps;

  try {
    const surveyLinkUUIDsDateTimeNotValid = chain(surveyResponses)
      .filter(
        ({ responseKey, value }) => responseKey === 'responseDateTime' && !value
      )
      .map('surveyLinkUUID')
      .value();

    const surveyResponsesNew = chain(surveyResponses)
      .filter(
        surveyResponse =>
          surveyResponse.value !== null &&
          !surveyLinkUUIDsDateTimeNotValid.includes(
            surveyResponse.surveyLinkUUID
          )
      )
      .map((surveyResponse, index) => {
        const {
          optionUUID,
          responseDataType,
          responseTypeClass,
          value,
          ...otherSurveyResponseProps
        } = surveyResponse;

        const responseKey = sdkUtils.getResponseKey({
          responseDataType,
          responseTypeClass
        });

        const surveyResponseClean = pickBy(
          {
            responseDataType,
            responseTypeClass,
            optionUUID,
            [responseKey]: value,
            orderNumber: index,
            ...otherSurveyResponseProps
          },
          property =>
            typeof property !== 'undefined' &&
            property !== null &&
            !isNaN(property)
        );

        return Object.assign({}, surveyResponseClean);
      })
      .value();

    if (surveyLinkUUIDsDateTimeNotValid.length > 0) {
      const surveyResponseUUIDs = chain(surveyLinkUUIDsDateTimeNotValid)
        .map(
          surveyLinkUUID =>
            chain(getState().cache.responses)
              .find({ surveyLinkUUID })
              .get('surveyResponseUUID')
              .value() || ''
        )
        .value();

      dispatch(
        deleteInstancesResponses({
          requestProps: {
            surveyResponseUUIDs
          }
        })
      );
    }

    const response = await sdk.postPutSurveyResponsesNoCache({
      ...otherRequestProps,
      surveyResponses: surveyResponsesNew
    });
    const { data = {}, status, statusText } = extractHttpResponse({ response });

    if (status === 201) {
      const surveyResponsesWithUUIDs = chain(surveyResponsesNew)
        .map(surveyResponse =>
          Object.assign({}, surveyResponse, {
            surveyResponseUUID: data[surveyResponse.orderNumber].id
          })
        )
        .value();
      const responses = keyBy(surveyResponsesWithUUIDs, 'surveyResponseUUID');

      return dispatch(
        fulfillAction({
          message: onFulfilledMessage || statusText,
          onFulfilled,
          responses,
          status,
          type: TYPES.POST_INSTANCES_RESPONSES_FULFILLED
        })
      );
    }

    throw new Error('Status not 201');
  } catch (error) {
    return dispatch(
      rejectAction({
        error,
        onRejected,
        onRejectedMessage,
        type: TYPES.POST_INSTANCES_RESPONSES_REJECTED
      })
    );
  }
};

/**
 * Post Instance Links
 */
export const postInstanceLinks = (props = {}) => async dispatch => {
  dispatch({
    type: TYPES.POST_INSTANCE_LINKS_PENDING
  });

  const {
    requestProps = {},
    onFulfilled,
    onFulfilledMessage,
    onRejected,
    onRejectedMessage
  } = props;
  const { instanceLinks, method } = requestProps;

  try {
    const response = await sdk.postPutInstanceLinks(requestProps);
    const { data = {}, status, statusText } = extractHttpResponse({ response });

    if (status === 201 || (method === 'put' && status === 200)) {
      const instanceLinksNew =
        method !== 'put'
          ? chain(instanceLinks)
              .map((instanceLink, index) =>
                Object.assign({}, instanceLink, {
                  instanceLinkUUID: data[index].id
                })
              )
              .value()
          : instanceLinks;

      return dispatch(
        fulfillAction({
          instanceLinks: keyBy(instanceLinksNew, 'instanceLinkUUID'),
          message: onFulfilledMessage || statusText,
          onFulfilled,
          status,
          type: TYPES.POST_INSTANCE_LINKS_FULFILLED
        })
      );
    }

    throw new Error('Status not 201');
  } catch (error) {
    return dispatch(
      rejectAction({
        error,
        onRejected,
        onRejectedMessage,
        type: TYPES.POST_INSTANCE_LINKS_REJECTED
      })
    );
  }
};

/**
 * Update Responses (only redux)
 */
export const updateInstancesReponses = (props = {}) => {
  const { newSurveyResponses = [], oldSurveyResponseUUIDs = [] } = props;

  return {
    payload: {
      newSurveyResponses,
      oldSurveyResponseUUIDs
    },
    type: TYPES.UPDATE_INSTANCES_RESPONSES
  };
};

/**
 * Fetch Instances Responses
 */
export const fetchInstancesResponsesFulfilled = (
  props = {}
) => async dispatch => {
  const { data } = props;
  const responses = keyBy(data, 'surveyResponseUUID');

  return dispatch({
    payload: {
      message: 'success',
      responses,
      status: 200
    },
    type: TYPES.FETCH_INSTANCES_RESPONSES_FULFILLED
  });
};
