/* eslint-disable max-params */
import { chain, filter, find, get } from 'lodash';
import { createSelector } from 'reselect';
import modelPatientProfile from '../utils/modelPatientProfile';

const instanceLinksSelector = ({ cache }) => cache.instanceLinks;
const questionsSelector = ({ cache }) => cache.questions;
const questionsOptionsSelector = ({ cache }) => cache.options;
const responsesSelector = ({ cache }) => cache.responses;
const patientProfileStoreSelector = ({ patientProfile }) => patientProfile;
const patientProfilesStoreSelector = ({ patientProfiles }) => patientProfiles;
const profilesStoreSelector = ({ profile }) => profile.profiles;
const medicationsStoreSelector = ({ medications }) => medications;

export const selectedPatientInstanceLinkSelector = createSelector(
  instanceLinksSelector,
  patientProfilesStoreSelector,
  (instanceLinks, patientProfilesStore) => {
    const selectedInstanceLinkUUID = get(
      patientProfilesStore,
      'selectedProfile.instanceLinkUUID'
    );

    return (
      find(instanceLinks, { instanceLinkUUID: selectedInstanceLinkUUID }) || {}
    );
  }
);

export const selectedPatientResponsesSelector = createSelector(
  responsesSelector,
  patientProfilesStoreSelector,
  (responses, patientProfilesStore) => {
    const selectedInstanceLinkUUID = get(
      patientProfilesStore,
      'selectedProfile.instanceLinkUUID'
    );

    return filter(responses, { instanceLinkUUID: selectedInstanceLinkUUID });
  }
);

export const medicationsStatusSelector = createSelector(
  medicationsStoreSelector,
  (state, { destinationStore }) => destinationStore,
  (medicationsStore, destinationStore) => {
    const medicationsStatus = medicationsStore[`${destinationStore}Status`];

    return medicationsStatus;
  }
);

export const patientProfilesSelector = createSelector(
  medicationsStoreSelector,
  instanceLinksSelector,
  questionsOptionsSelector,
  responsesSelector,
  profilesStoreSelector,
  (
    medicationsStore,
    instanceLinks,
    surveyOptions,
    surveyResponses,
    profiles
  ) => {
    const patientProfiles = chain(instanceLinks)
      .sort((a, b) => {
        if (a.recordChangeDateTime > b.recordChangeDateTime) {
          return -1;
        }

        if (a.recordChangeDateTime < b.recordChangeDateTime) {
          return 1;
        }

        return 0;
      })
      .groupBy(instanceLink => instanceLink.profileUUID)
      .reduce(
        (acc, groups) =>
          chain(groups)
            .head()
            .thru(instanceLink =>
              modelPatientProfile({
                medicationsStore,
                fullPatientProfile: false,
                instanceLink,
                profile: profiles[instanceLink.profileUUID],
                surveyOptions,
                surveyResponses
              })
            )
            .concat(acc)
            .value(),
        []
      )
      .value();

    console.log('patientProfilesSelector patientProfiles', patientProfiles);

    return patientProfiles;
  }
);

export const patientProfileSelector = createSelector(
  medicationsStoreSelector,
  patientProfileStoreSelector,
  selectedPatientInstanceLinkSelector,
  questionsSelector,
  questionsOptionsSelector,
  responsesSelector,
  profilesStoreSelector,
  (state, { destinationStore }) => destinationStore,
  (
    medicationsStore,
    patientProfileStore,
    selectedPatientInstanceLink,
    surveyQuestions,
    surveyOptions,
    surveyResponses,
    profiles,
    destinationStore
  ) => {
    const modeledPatientProfile = modelPatientProfile({
      medicationsStore,
      destinationStore,
      fullPatientProfile: true,
      instanceLink: selectedPatientInstanceLink,
      profile: profiles[selectedPatientInstanceLink.profileUUID],
      surveyOptions,
      surveyQuestions,
      surveyResponses
    });

    const patientProfile = Object.assign(
      {},
      modeledPatientProfile,
      patientProfileStore
    );

    console.log('patientProfileSelector patientProfile', patientProfile);

    return patientProfile;
  }
);

export default {
  medicationsStatusSelector,
  patientProfileSelector,
  patientProfilesSelector
};
