const basePublicPath = '/main';
const baseSecureDoctorPath = '/secure/doctor';

// secure doctor
export const URL_DOCTOR_LOGIN = `${basePublicPath}/doctor/login`;
export const URL_SECURE_DOCTOR_CDSS_CHART_NOTES_TESTS = `${baseSecureDoctorPath}/cdss/tests/chart_notes`;
export const URL_SECURE_DOCTOR_CDSS_TRANSFORM_MEDICATION_TESTS = `${baseSecureDoctorPath}/cdss/tests/transform_medications`;
export const URL_SECURE_DOCTOR_CDSS_PATIENTS = `${baseSecureDoctorPath}/cdss/patients`;
export const URL_SECURE_DOCTOR_CDSS_PATIENT = `${baseSecureDoctorPath}/cdss/patients/:patientHash`;
export const URL_SECURE_DOCTOR_CDSS_PATIENT_ASTHMA_CONTROL = `${URL_SECURE_DOCTOR_CDSS_PATIENT}/asthma_control`;
export const URL_SECURE_DOCTOR_CDSS_PATIENT_MEDICATION_CHANGE = `${URL_SECURE_DOCTOR_CDSS_PATIENT}/medication_change`;
export const URL_SECURE_DOCTOR_CDSS_PATIENT_AAP = `${URL_SECURE_DOCTOR_CDSS_PATIENT}/asthma_action_plan`;
export const URL_SECURE_DOCTOR_CDSS_PATIENT_AAP_REPORT = `${URL_SECURE_DOCTOR_CDSS_PATIENT}/asthma_action_plan/report`;
export const URL_SECURE_DOCTOR_CDSS_PATIENT_COMPLETE = `${URL_SECURE_DOCTOR_CDSS_PATIENT}/complete`;

export const URL_SECURE_DOCTOR_QUESTIONNAIRE = `${baseSecureDoctorPath}/questionnaire`;

export default {
  URL_DOCTOR_LOGIN,
  URL_SECURE_DOCTOR_CDSS_CHART_NOTES_TESTS,
  URL_SECURE_DOCTOR_CDSS_TRANSFORM_MEDICATION_TESTS,
  URL_SECURE_DOCTOR_CDSS_PATIENTS,
  URL_SECURE_DOCTOR_CDSS_PATIENT,
  URL_SECURE_DOCTOR_CDSS_PATIENT_ASTHMA_CONTROL,
  URL_SECURE_DOCTOR_CDSS_PATIENT_MEDICATION_CHANGE,
  URL_SECURE_DOCTOR_CDSS_PATIENT_AAP,
  URL_SECURE_DOCTOR_CDSS_PATIENT_AAP_REPORT,
  URL_SECURE_DOCTOR_CDSS_PATIENT_COMPLETE,
  URL_SECURE_DOCTOR_QUESTIONNAIRE
};
